import * as React from "react"

import { Helmet } from "react-helmet"
import SEO from "../components/seo";

import { graphql } from "gatsby"
import Img from "gatsby-image"

import "../main.scss"
import {Instagram} from "../components/socials/Instagram";
import {Facebook} from "../components/socials/Facebook";
import {IconContext} from "react-icons";


export default function Home({ data }) {

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]


  return <div className={'splash'}>
      <SEO
          title={"Home"}
          description={"Groove your way to becoming the next Dragon Drummer in this upcoming rhythm game!"}
      />
      <div>
          <Img fluid={sources} />
      </div>
      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}>
        <p style={{
          display: "flex",
          alignItems: 'center'
        }}>
          <IconContext.Provider value={{size: "2em", color: "#DC143C"}}>
            <Instagram isLarge={true}/>
            <Facebook isLarge={true}/>
          </IconContext.Provider>
        </p>
        <div>
          <h1 style={{
            fontWeight: "bold",
            fontSize: "1.5em",
            margin: "0.5em",
            textAlign: "center"
          }}>Under construction, check back soon!</h1>
        </div>
      </div>

      {/*<nav className="level">*/}
      {/*    <div className="level-item has-text-centered">*/}
      {/*        <div>*/}
      {/*            <a className={"button is-size-3 is-primary"} href={"/play"}>Play</a>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*    <div className="level-item has-text-centered">*/}
      {/*        <div>*/}
      {/*            <a className={"button is-size-3 is-primary"} href={"/blog"}>Blog</a>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</nav>*/}
  </div>
}

export const query = graphql`
  query {
    desktopImage: file(relativePath: { eq: "banner.png" }) {
      childImageSharp {
        # i.e. the max width of your container is 700 pixels.
        #
        # Other options include maxHeight (set both maxWidth and maxHeight to crop),
        # grayscale, duotone, rotate, etc.
        fluid(maxWidth: 1600) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid
        }
      }
    },
    mobileImage: file(relativePath: { eq: "mobilebanner.png" }) {
      childImageSharp {
        # i.e. the max width of your container is 700 pixels.
        #
        # Other options include maxHeight (set both maxWidth and maxHeight to crop),
        # grayscale, duotone, rotate, etc.
        fluid(maxWidth: 1000) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
